<template>
  <header>
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
  </header>
  <footer>
    <router-link to="/privacy">Privacy</router-link>
    <span class="right">&copy; Copyright {{ year }} technostropic</span>
  </footer>
  <main>
    <div class="content">
      <router-view />
    </div>
  </main>
</template>

<style>
@import "@/assets/base.css";
</style>
<script>
export default {
  name: "App",
  data() {
    return {
      year: 2022,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const d = new Date();
      this.year = d.getFullYear();
    },
  },
};
</script>
