<template>
  <div class="home">
    <vue-headful
      title="blog.technostropic - Home"
      description="The personal blog of technostropic - Simon Reed"
    />
    <a
      :href="'https://' + hostname.replace('blog.', '')"
      title="Profile of Simon Reed - technostropic"
    >
      <img
        class="profile-picture"
        itemprop="image"
        src="https://www.gravatar.com/avatar/aca3affa8eed98872936da1b1443a77b?s=200"
        alt="Profile picture of Simon Reed - technostropic"
      />
    </a>
    <h1 v-if="page">{{ page.title }}</h1>
    <sub v-if="page">
      {{ page.excerpt }}
    </sub>
    <div v-if="page">
      <SanityBlocks :blocks="page.body" />
    </div>
    <div class="home-main">
      <div class="latest-posts">
        <h2>Latest Posts</h2>
        <div class="posts">
          <div class="loading" v-if="loading">Loading...</div>
          <div v-if="error" class="error">
            {{ error }}
          </div>
          <div class="container">
            <div v-for="post in posts" class="post-item" :key="post._id">
              <hr />
              <router-link :to="`/blog/${post.slug.current}`">
                <sub class="right">{{
                  new Date(post.publishedAt).toLocaleString()
                }}</sub>
                <h3>{{ post.title }}</h3>
              </router-link>
              <img
                v-if="post.mainImage"
                :src="imageUrlFor(post.mainImage).width(480)"
              />
              <div class="content">
                {{ post.excerpt }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside class="side-nav">
        <ul>
          <li>
            <router-link :to="`/posts`"> All Posts </router-link>
          </li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
        </ul>
      </aside>
    </div>
    <hr />
    <div v-if="page">
      <sub class="right"
        >This page was last updated on
        {{ new Date(page.updated).toLocaleString() }}</sub
      >
    </div>
  </div>
</template>
<script>
import sanity from "../client";
import { SanityBlocks } from "sanity-blocks-vue-component";

import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);
const query = `{
"page":*[_type == "page" && name=="home"]{_id,title,"updated": _updatedAt,excerpt,body,mainImage}[0],
"postCount":count(*[_type == "post"]),
"posts":*[_type == "post"]{
  _id,
  title,
  publishedAt,
  slug,
  excerpt,
  mainImage
}| order(publishedAt desc)[0...5]
}`;

export default {
  name: "HomeView",
  components: { SanityBlocks },
  data() {
    return {
      loading: true,
      posts: [],
      page: null,
      hostname: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
    fetchData() {
      this.hostname = window.location.hostname;
      this.error = this.post = null;
      this.loading = true;
      sanity.fetch(query).then(
        (result) => {
          this.loading = false;
          this.page = result.page;
          this.posts = result.posts;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>
